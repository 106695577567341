export const OutboundDataElements = [
    {
        dBKey: 'CustomerName',
        displayName: 'Customer',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },
    {
        dBKey: 'LoadDepartDT',
        displayName: 'Load Depart Date',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'HouseRef',
        displayName: 'House Ref',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 135
    },
    {
        dBKey: 'Master',
        displayName: 'Master',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 135
    },
    {
        dBKey: 'Orders',
        displayName: 'PO #',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 300
    },
    {
        dBKey: 'PCS',
        displayName: 'PCS',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },
    {
        dBKey: 'Weight',
        displayName: 'Weight',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },
    {
        dBKey: 'Pallets',
        displayName: 'Pallets',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 110
    },
    {
        dBKey: 'DestinationName',
        displayName: 'Destination',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 160
    },
    {
        dBKey: 'DCCode',
        displayName: 'DC Code',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },
    {
        dBKey: 'Carrier',
        displayName: 'Carrier',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },
        {
        dBKey: 'Trailer',
        displayName: 'Trailer',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },
    {
        dBKey: 'Seal',
        displayName: 'Seal',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'BOL',
        displayName: 'BOL #',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'InWarehouseHours',
        displayName: 'In Warehouse Hours',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'DCRequiredDate',
        displayName: 'NDC Date',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'ConsigneeName',
        displayName: 'Consignee',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },
    {
        dBKey: 'WarehouseName',
        displayName: 'Warehouse',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },

]


export default OutboundDataElements

