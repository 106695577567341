export const MawbDataElements = [
    {
        dBKey: 'Master',
        displayName: 'Master',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 135
    },
    {
        dBKey: 'CustomerName',
        displayName: 'Customer',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },
    {
        dBKey: 'MawbColorCode',
        displayName: 'Color',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 0
    },

    {
        dBKey: 'MawbStatusName',
        displayName: 'Status',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'ExpectedCartons',
        displayName: 'Expected Cartons',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 140
    },
    {
        dBKey: 'TotCartons',
        displayName: 'Total Cartons',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 110
    },
    {
        dBKey: 'RecoveredPCS',
        displayName: 'Recovered Cartons',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'RecoveredPallets',
        displayName: 'Recovered Pallets',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'TotChargeable',
        displayName: 'Weight (CHW)',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 120
    },
    {
        dBKey: 'CarrierName',
        displayName: 'Carrier',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 250
    },
    {
        dBKey: 'LFD',
        displayName: 'LFD',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'PlannedPickupDT',
        displayName: 'Planned Pickup Date',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },

    {
        dBKey: 'PickupDT',
        displayName: 'Pickup Date',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'DriverAssigned',
        displayName: 'Assigned Driver',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'ArrivalAtWarehouse',
        displayName: 'Arrival At Warehouse',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'AirlineRecovered',
        displayName: 'Airline Recovered',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'Trips',
        displayName: 'Trips',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'PMC',
        displayName: 'PMC',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'Notes',
        displayName: 'Notes',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 300
    },


    {
        dBKey: 'WarehouseName',
        displayName: 'Warehouse',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },









    







    // {
    //     dBKey: 'TotVolume',
    //     displayName: 'Total Volume',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true
    // },


    // {
    //     dBKey: 'FlightVoyage',
    //     displayName: 'Flight/Voyage',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 130
    // },
    // {
    //     dBKey: 'MinETADischarge',
    //     displayName: 'Min ETA Discharge',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 200
    // },
 

 

 
    // {
    //     dBKey: 'No',
    //     displayName: 'No',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 150
    // }, 

    // {
    //     dBKey: 'PickupBy',
    //     displayName: 'Pickup By',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 150
    // },


    // {
    //     dBKey: 'ArrivalTime',
    //     displayName: 'Arrival Date',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 200
    // },


    // {
    //     dBKey: 'DeliveryTime',
    //     displayName: 'Delivery Date',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 200
    // },

    


]


export default MawbDataElements

